import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterLink, IonRouterOutlet, IonSpinner, IonSplitPane, IonThumbnail, IonToolbar } from '@ionic/angular/standalone';
import { Store, select } from '@ngrx/store';
import { addIcons } from "ionicons";
import { card, chatboxEllipses, logOut, person, planet, timer } from "ionicons/icons";
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { signOut } from '../auth/state/auth/auth.actions';
import { loggedInUser } from '../auth/state/auth/auth.selectors';
import { loadSites } from '../state/sites/sites.actions';
import { selectAllSites } from '../state/sites/sites.selectors';
import { SynapsMediaApiService } from '../synaps-media-api.service';
import Logger from '../logger.service';

const log = Logger('page:layout');

@Component({
    selector: 'app-layout',
    templateUrl: './layout.page.html',
    styleUrls: ['./layout.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonToolbar, IonHeader, RouterLink, RouterLinkActive, AsyncPipe, IonRouterLink, IonSplitPane, IonMenu, IonContent, IonList, IonItem, IonLabel, IonMenuToggle, IonIcon, IonThumbnail, IonSpinner, IonFooter, IonRouterOutlet],
})
export class LayoutPage implements OnInit {
    activeUser$ = this.store.pipe(
        select(loggedInUser),
        tap((user) => log('user', user)),
    );

    sites$ = this.store.pipe(select(selectAllSites)).pipe(
      filter(sites => sites !== null),
      map((sites) => sites.map((site) => ({
        ...site,
        info: {
          ...site.info,
          logo: site.info.logo?.startsWith('/') ? `${site.info.url}${site.info.logo}` : site.info.logo,
          icon: site.info.icon?.startsWith('/') ? `${site.info.url}${site.info.icon}` : site.info.icon,
          cover_image: site.info.cover_image?.startsWith('/') ? `${site.info.url}${site.info.cover_image}` : site.info.cover_image,
        },
      }))),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    portalUrl = this.api.billingPortalRedirectUrl;

    constructor(
        private store: Store,
        private api: SynapsMediaApiService,
    ) {
        addIcons({ planet, timer, card, person, chatboxEllipses, logOut });
    }

    ngOnInit() {
      this.store.dispatch(loadSites())
    }

    help() {
      window.$chatwoot.toggle();
    }

    signOut() {
        this.store.dispatch(signOut());
    }
}
