import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LayoutPage } from './layout.page';
import { select, Store } from '@ngrx/store';
import { SynapsMediaApiService } from '../synaps-media-api.service';
import { catchError, map, of, tap } from 'rxjs';
import { loadSiteDetail } from '../state/sites/sites.actions';
// import { TawkMessengerAngularModule } from '@tawk.to/tawk-messenger-angular/dist/tawk-messenger-angular';


export const SiteGuard: CanActivateFn = (route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const api = inject(SynapsMediaApiService);
  const router = inject(Router);
  const store = inject(Store);

  const siteId = route.paramMap.get('siteId');

  // return api.getSite(siteId).pipe(
  //   map((site) => !!site),
  //   catchError(() => {
  //     router.navigate(['/dashboard']);
  //     return of(false);
  //   })
  // );

  return store.pipe(
      select(loadSiteDetail, { siteId }),
      // Check if loggedIn is boolean
      map((site) => !!site),
      tap((site) => {
        if (site === false) {
          router.navigate(['/dashboard']);
        }
      })
    );
};

export const DomainGuard: CanActivateFn = (route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const api = inject(SynapsMediaApiService);
  const router = inject(Router);

  const domainId = route.paramMap.get('domainId');
  const siteId = route.paramMap.get('siteId');

  return api.getSiteDomain(siteId, domainId).pipe(
    map((domain) => !!domain),
    catchError(() => {
      router.navigate(['/sites/' + siteId]);
      return of(false);
    })
  );
};

export const siteRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../site-home/site-home.module').then( m => m.SiteHomePageModule)
  },
  {
    path: 'domains/add',
    loadChildren: () => import('../add-domain/add-domain.module').then( m => m.AddDomainPageModule)
  },
  {
    path: 'domains/:domainId',
    canActivate: [DomainGuard],
    loadChildren: () => import('../add-domain/add-domain.module').then( m => m.AddDomainPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../site-settings/site-settings.module').then( m => m.SiteSettingsPageModule)
  },

];

const routes: Routes = [
  {
    path: '',
    component: LayoutPage,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'sites',
        loadChildren: () => import('../sites/sites.module').then( m => m.SitesPageModule)
      },
      {
        path: 'sites/:siteId',
        canActivate: [SiteGuard],
        children: siteRoutes
      },
      {
        path: 'billing',
        loadChildren: () => import('../billing/billing.module').then( m => m.BillingPageModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then( m => m.SettingsPageModule)
      },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    // TawkMessengerAngularModule,
  ],
  exports: [RouterModule],
})
export class LayoutPageRoutingModule {}
